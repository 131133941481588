import "core-js/modules/es.array.push.js";
import middle from "@/assets/img/main-middle.svg";
import ChooseModal from "@/components/ChooseModal.vue";
import { Editor } from "@wangeditor/editor-for-vue";
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
const cheerio = require("cheerio");
export default {
  name: "EditDraftPost",
  components: {
    ChooseModal,
    Editor
  },
  props: ["page", "file", "edit", "origin", "id"],
  data() {
    return {
      window,
      editor: null,
      showAddFn: false,
      // 存储首页颜色list
      color: ["#fef8e7", "#8cc9de", "#47c0a9", "#cb85d6", "#ee8b34", "#53ab61"],
      // 存储选中的首页颜色index
      fileList: [],
      // 存储首页编辑数据
      editInfo: {
        title: "",
        content: "",
        radioClub: null,
        colorIndex: 0
      },
      text: "",
      // choose a club弹框
      showChooseModal: false,
      // 路由守卫是否继续往下走，用于draft有编辑时询问要不要保存
      next: null
    };
  },
  watch: {
    file: {
      immediate: true,
      //初始化时让handler调用一下
      handler(newValue) {
        if (this.page) {
          this.fileList = newValue;
        }
      }
    },
    edit: {
      immediate: true,
      //初始化时让handler调用一下
      handler(newValue) {
        if (this.page) {
          this.editInfo = newValue;
        }
      }
    }
  },
  methods: {
    handleCreated(editor) {
      this.editor = editor; // 记录 editor 实例，重要！
    },
    handleChange($event) {
      //  ( 和 ): 表示分组，用于将匹配到的内容分组。
      // [^#\s] 表示任意不是 '#' 或空白字符的字符，* 表示匹配零个或多个这样的字符。

      if (this.text !== $event.getText()) {
        let html = this.editInfo.content;
        this.text = $event.getText();
        const regex = /<p>(.*?)<\/p>/g;
        const matches = html.match(regex);
        if (matches) {
          // 对每个匹配到的<p>标签中的文本内容进行替换
          matches.forEach(match => {
            const $ = cheerio.load(match);
            const extractedText = $("p").text();
            const replacedText = extractedText.replace(/(\s#)([^#\s]*)/g, `<span style="color:#F04E37;">$&</span>`);
            html = html.replace(match, "<p>" + replacedText + "</p>");
          });
        }
        if (this.editInfo.content !== html) {
          this.editor.setHtml(html);
        }
      }
    },
    insert() {
      // if(!this.editor.isFocused()){
      // this.editor.focus()

      // }
      this.editor.insertText("<span> #</span>");
      this.editor.focus();
    },
    // 预览图片
    preview(index) {
      document.getElementById("preview-img" + index).click();
    },
    // 删除图片
    handleRemove(index) {
      this.fileList.splice(index, 1);
    },
    // 上传文件成功后将其添加至fileList
    upload(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      this.$http.post("/api/file/fileUpload", formData).then(({
        data: res
      }) => {
        if (res.success) {
          this.fileList.push({
            name: res.data.fileName,
            url: res.data.url,
            // 鼠标悬浮图片上面出现删除按钮
            imageDelete: false
          });
        }
      }).catch(() => {});
    },
    beforeUpload(file) {
      if (file.type.indexOf("image") > -1) {
        return true;
      } else {
        return false;
      }
    },
    changeClub(event) {
      this.editInfo.radioClub = event;
      this.showChooseModal = false;
    },
    // 点击首页编辑颜色存储对应index
    changeColor(index) {
      this.editInfo.colorIndex = index;
      if (window.innerWidth <= 768) {
        // 移动端时修改 paper 背景色
        this.$refs.middle.parentElement.style.backgroundColor = this.color[this.editInfo.colorIndex];
      } else {
        // PC端保持原有逻辑
        const elm = this.$refs.middle.querySelector("#middle-color");
        elm.setAttribute("style", "fill:" + this.color[this.editInfo.colorIndex]);
      }
    },
    tip(content) {
      this.$tipModal({
        visible: true,
        tipObj: {
          title: "",
          content,
          footer: [{
            name: "Close",
            type: undefined,
            query: "close",
            class: "default-button"
          }]
        }
      });
    },
    cancel() {
      this.editInfo.title = "";
      this.editInfo.content = "";
      this.editInfo.radioClub = null;
      this.editInfo.colorIndex = 0;
      this.fileList = [];
      this.changeColor(this.editInfo.colorIndex);
    },
    // 接收要调保存草稿接口还是post接口
    async receive(event) {
      let nojudge;
      if (!this.page) {
        if (event === "save" || event === "saveDraft") {
          nojudge = await this.saveDraftPost("draft");
        } else if (event === "savePost") {
          nojudge = await this.saveDraftPost("post");
        }
        // else if (event === "cancel") {
        //   for (const key in this.editInfo) {
        //     if (Object.hasOwnProperty.call(this.editInfo, key)) {
        //       this.editInfo[key] = "";
        //     }
        //   }
        //   this.fileList = [];
        // }
        if (!nojudge) {
          if (this.next) {
            this.getInfoJudge(this.$http, this.$particle).then(() => {
              this.next();
              this.next = null;
            });
          } else {
            document.querySelector(".app").scrollTop = 700;
          }
        }
      } else {
        if (event === "saveDraft") {
          await this.saveDraftPost("draft");
        } else if (event === "savePost") {
          await this.saveDraftPost("post");
        }
      }
      return "";
    },
    // 判断是否输入必填可以保存
    judgeSave() {
      if (!this.editInfo.title.trim()) {
        this.tip("Please maintain the title");
        return true;
      }
      if (!this.editInfo.content.trim()) {
        this.tip("Please maintain the content");
        return true;
      }
      if (!this.editInfo.radioClub?.id) {
        this.tip("Please maintain the club");
        return true;
      }
    },
    savePostJudge() {
      // if (this.judgeSave()) {
      //   return;
      // }
      if (!this.page) {
        this.$tipModal({
          visible: true,
          tipObj: {
            title: "",
            content: "You want to choose whether to save as a draft or a post",
            footer: [{
              name: "Cancel",
              type: undefined,
              query: "cancel",
              class: "default-button",
              fn: this.receive
            }, {
              name: "Save the draft",
              type: "primary",
              query: "saveDraft",
              class: "primary-button",
              fn: this.receive
            }, {
              name: "Save the post",
              type: "primary",
              query: "savePost",
              class: "primary-button",
              fn: this.receive
            }]
          }
        });
      } else {
        if (this.origin === "draft") {
          this.$tipModal({
            visible: true,
            tipObj: {
              title: "",
              content: "You want to choose whether to save as a draft or a post",
              footer: [{
                name: "Cancel",
                type: undefined,
                query: "cancel",
                class: "default-button",
                fn: this.receive
              }, {
                name: "Save the draft",
                type: "primary",
                query: "saveDraft",
                class: "primary-button",
                fn: this.receive
              }, {
                name: "Save the post",
                type: "primary",
                query: "savePost",
                class: "primary-button",
                fn: this.receive
              }]
            }
          });
        } else {
          this.saveDraftPost("post");
        }
      }
    },
    // 保存draft或post
    saveDraftPost(type) {
      return new Promise(resolve => {
        if (type === "post") {
          if (this.judgeSave()) {
            resolve(true);
            return;
          }
        }
        if (!this.page) {
          const params = {
            clubId: this.editInfo?.radioClub?.id,
            title: this.editInfo?.title?.trim(),
            content: this.editInfo?.content?.trim(),
            imageUrls: this.fileList?.map(e => e.url),
            backgroundColor: this.editInfo.colorIndex === 0 ? "default" : this.color[this.editInfo.colorIndex]
          };
          this.$http.post(`/api/${type}/create`, params).then(({
            data: res
          }) => {
            if (res.success) {
              for (const key in this.editInfo) {
                if (Object.hasOwnProperty.call(this.editInfo, key)) {
                  this.editInfo[key] = "";
                }
              }
              this.fileList = [];
              resolve();
            }
          }).catch(() => {});
        } else {
          const params = {
            id: this.origin === "draft" && type === "post" || !this.id ? undefined : this.id,
            clubId: this.editInfo?.radioClub?.id,
            title: this.editInfo?.title?.trim(),
            content: this.editInfo?.content?.trim(),
            imageUrls: this.fileList?.map(e => e.url),
            backgroundColor: this.editInfo.colorIndex === 0 ? "default" : this.color[this.editInfo.colorIndex]
          };
          const url = this.origin === "draft" && type === "post" || !this.id ? `api/${type}/create` : `api/${type}/updateOne`;
          this.$http.post("/" + url, params).then(({
            data: res
          }) => {
            if (res.success) {
              if (this.origin === "draft" && type === "post") {
                this.$http.post("/api/draft/delete?draftId=" + this.id).then(() => {}).catch(() => {});
              }
              this.$router.back();
              resolve();
            }
          }).catch(() => {});
        }
      });
    },
    // 判断首页是否有值，有值的话离开页面要提示需不需要存草稿(路由守卫+首页右侧scroll按钮)
    judgeEditinfo() {
      const data = Object.keys(this.editInfo).filter(e => e !== "content").map(val => this.editInfo[val]);
      if (data.some(e => e) || this.fileList.length || this.editor.getText()) {
        return true;
      }
    },
    // 首页编辑左右两侧置灰
    dontEdit($event) {
      if (!this.page) {
        this.$emit("dontEdit", $event);
      }
    }
  },
  mounted() {
    fetch(middle).then(response => response.text()).then(svgContent => {
      this.$refs.middle.innerHTML = svgContent;
    });
  },
  unmounted() {
    if (this.editor == null) return;
    this.editor.destroy();
  }
};