import "core-js/modules/es.array.push.js";
// import picker compopnent
import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/css";
import SharedModal from "./SharedModal";
export default {
  name: "CommentAll",
  props: ["obj"],
  components: {
    //注册组件，不能全局挂载
    EmojiPicker,
    SharedModal
  },
  data() {
    return {
      detail: null,
      mission: [],
      missionTotal: 0,
      // 加载中
      loading: true,
      missionIndex: 1,
      commentInput: "",
      replayObj: null,
      emojiVisble: false,
      reportUrl: null,
      showReport: false
    };
  },
  computed: {
    // 无法展示更多
    noMore() {
      return this.mission.length === this.missionTotal;
    },
    // 加载中防抖节流
    disabled() {
      return this.loading;
    }
  },
  methods: {
    openInApp() {
      if (this.obj.origin === 'post') {
        window.location.href = `funtana://view.post/${this.obj.id}`;
      } else if (this.obj.origin === 'assignment') {
        window.location.href = `funtana://view.ass/${this.obj.id}`;
      }
    },
    getDetailData() {
      const params = {
        [`${this.obj.origin}Id`]: this.obj.id,
        timeZone: this.$timezone
      };
      this.$http({
        url: `/api/${this.obj.origin}/getDetail`,
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.detail = {
            ...res.data,
            setTimeout: null,
            likedByMeOrigin: res.data.likedByMe
          };
        }
      }).catch(() => {});
    },
    // 关注
    followOther() {
      if (this.isLogin()) {
        return;
      }
      const url = this.detail.followedByMe ? "api/friendShip/unfollow" : "api/friendShip/follow";
      this.$http.post("/" + url + "?userId=" + this.detail.userId).then(({
        data: res
      }) => {
        if (res.success) {
          this.detail.followedByMe = !this.detail.followedByMe;
        }
      }).catch(() => {});
    },
    load() {
      if (this.missionIndex * 10 < this.missionTotal) {
        this.loading = true;
        this.missionIndex++;
        this.getData(false);
      }
    },
    async getData(reset) {
      if (reset) {
        this.mission = [];
        this.missionIndex = 1;
      }
      const data = await this.getAllData();
      this.mission = [...this.mission, ...data];
      this.loading = false;
      return true;
    },
    // filter为all
    getAllData() {
      return new Promise(resolve => {
        const params = {
          objId: this.obj.id,
          timeZone: this.$timezone,
          page: this.missionIndex,
          size: 10
        };
        this.$http({
          url: "/api/comment/listMain",
          //请求的后台接口
          method: "get",
          //get请求方式
          params
        }).then(({
          data: res
        }) => {
          if (res.success) {
            this.missionTotal = res.data.totalCount;
            const data = res.data.list.map(e => {
              return {
                ...e,
                children: [],
                pageIndex: 0,
                setTimeout: null,
                likedByMeOrigin: e.likedByMe
              };
            });
            resolve(data);
          }
        }).catch(() => {});
      });
    },
    back() {
      this.$emit("commentBack");
    },
    expand(item) {
      item.pageIndex++;
      const params = {
        parentId: item.id,
        timeZone: this.$timezone,
        page: this.missionIndex,
        size: 10
      };
      this.$http({
        url: "/api/comment/listReply",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          // item.replyCount = res.data.totalCount;
          const data = res.data.list.map(e => {
            return {
              ...e,
              setTimeout: null,
              likedByMeOrigin: e.likedByMe
            };
          });
          item.children.push(...data);
        }
      }).catch(() => {});
    },
    sendComment() {
      event.preventDefault();
      const replayData = JSON.parse(JSON.stringify(this.replayObj));
      if (this.commentInput) {
        const params = {
          content: this.commentInput,
          bisId: replayData ? undefined : this.detail.id,
          parentId: replayData ? replayData.item.id : undefined,
          toUserId: replayData ? replayData.item.fromUserId : this.detail.userId
        };
        const url = replayData ? "api/comment/reply" : `api/${this.obj.origin}/sendComment`;
        this.$http.post(`/${url}`, params).then(({
          data: res
        }) => {
          const responseData = {
            ...res.data,
            likeCount: 0,
            replyCount: 0,
            timeDiff: "1 minute ago",
            fromUserName: this.info.userName,
            fromUserAvatar: this.info.avatar,
            children: [],
            pageIndex: 0
          };
          if (res.success) {
            if (!replayData) {
              this.mission.unshift(responseData);
            } else {
              if (!this.mission[replayData.index].children.length) {
                this.mission[replayData.index].replyCount++;
              } else {
                this.mission[replayData.index].children.unshift(responseData);
              }
            }
            this.commentInput = "";
            this.detail.commentCount++;
          }
        }).catch(() => {});
      }
    },
    replay(item, index) {
      if (this.isLogin()) {
        return;
      }
      this.replayObj = {
        index,
        item
      };
      this.$refs.input.focus();
    },
    changeEmoji($event) {
      this.emojiVisble = false;
      this.commentInput += $event.i;
    },
    chooseMenu(event) {
      if (event === "jvbao") {
        if (this.isLogin()) {
          return;
        }
        // this.$message.success("Report successful");
        this.$tipModal({
          visible: true,
          tipObj: {
            title: "",
            content: "Report successful",
            footer: [{
              name: "Close",
              type: undefined,
              query: "close",
              class: "default-button"
            }]
          }
        });
      }
    },
    shared() {
      this.showReport = true;
      this.reportUrl = {
        url: location.origin + "/#/?shared=true&id=" + this.obj.id + "&origin=" + this.obj.origin,
        data: this.obj
      };
    },
    report($event) {
      if ($event !== "cancel") {
        this.detail.shareCount++;
        this.postShared(this.reportUrl.data, $event);
      }
      this.showReport = false;
    },
    toOther(item, query) {
      if (this.isLogin()) {
        return;
      }
      if (item[query] !== this.info.id) {
        this.$router.push({
          name: "FollowOther",
          query: {
            userId: item[query]
          }
        });
      } else {
        this.$router.push({
          name: "PersonalManage",
          query: {
            index: "3"
          }
        });
      }
    },
    dianzan(item, type) {
      if (this.isLogin()) {
        return;
      }
      this.clickLike(item, type);
    }
  },
  created() {
    this.getDetailData();
    this.getData(true);
  }
};