import "core-js/modules/es.array.push.js";
import leftTip from "@/assets/img/main-left.svg";
import rightTip from "@/assets/img/main-right.svg";
import EditDraftPost from "@/components/EditDraftPost.vue";
import MissionCard from "@/components/MissionCard.vue";
import ClubCard from "@/components/ClubCard.vue";
import PostCard from "@/components/PostCard.vue";
import Masonry from "masonry-layout";
import CommentAll from "@/components/CommentAll.vue";
export default {
  name: "SelfHome",
  components: {
    EditDraftPost,
    MissionCard,
    ClubCard,
    PostCard,
    CommentAll
  },
  data() {
    return {
      // 首页编辑时左右两侧不可点击
      leftRightCover: false,
      // 展示是首页左侧tip
      showLeftTip: false,
      // 存储mission列表
      missions: [],
      missionGroups: [],
      missionMenu: "all",
      // 存储club列表
      clubs: [],
      clubGroups: [],
      clubMenu: "all",
      content: [],
      contentIndex: 1,
      contentTotal: 20,
      // content加载中
      loading: false,
      // content是否展示回到顶部按钮
      showScrollTop: false,
      showComment: false,
      commentObj: null
    };
  },
  computed: {
    // content无法展示更多
    noMore() {
      return this.content.length === this.contentTotal;
    },
    // 加载中防抖节流
    disabled() {
      return this.loading;
    }
  },
  // watch: {
  //   isMobile: {
  //     immediate: true, //初始化时让handler调用一下
  //     handler(newValue) {
  //       if (newValue <= 750) {
  //         this.mission = this.mission.flat();
  //         this.club = this.club.flat();
  //         this.changeList(2, "mission");
  //         this.changeList(2, "club");
  //       } else {
  //         this.mission = this.mission.flat();
  //         this.club = this.club.flat();
  //         this.changeList(8, "mission");
  //         this.changeList(6, "club");
  //       }
  //     },
  //   },
  // },
  methods: {
    dontEdit($event) {
      this.leftRightCover = $event;
    },
    // 跳转至个人管理页面
    goPersonal(index) {
      this.$router.push({
        name: "PersonalManage",
        query: {
          index
        }
      }).catch(() => {});
    },
    // 获取club列表
    getClubData() {
      const params = {
        timeZone: this.$timezone,
        name: "",
        page: 1,
        size: 24
      };
      this.$http({
        url: "/api/club/listPopular",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.clubs = res.data?.list || [];
          this.clubGroups = this.getGroupData(6, "clubs");
        }
      }).catch(() => {});
    },
    // 获取mission列表
    getMissionData() {
      const params = {
        timeZone: this.$timezone,
        page: 1,
        size: 32
        // onlineState: this.missionMenu,
      };
      this.$http({
        url: "/api/mission/listTop",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.missions = res.data?.list || [];
          this.missionGroups = this.getGroupData(8, "missions");
        }
      }).catch(() => {});
    },
    getGroupData(num, key) {
      var group = this[key].reduce((result, element, index) => {
        // 计算当前元素所在的行数和列数
        const row = Math.floor(index / num);
        const column = index % num;
        // 将元素添加到相应的行
        if (!result[row]) {
          result[row] = [];
        }
        result[row][column] = element;
        return result;
      }, []);
      return group;
    },
    // 获取post列表
    getPostData() {
      return new Promise(resolve => {
        const params = {
          timeZone: this.$timezone,
          page: this.contentIndex,
          size: 10
        };
        this.$http({
          url: "/api/post/listCreative",
          //请求的后台接口
          method: "get",
          //get请求方式
          params
        }).then(({
          data: res
        }) => {
          if (res.success) {
            const data = res.data.list.map(e => {
              return {
                ...e,
                setTimeout: null,
                likedByMeOrigin: e.likedByMe
              };
            });
            this.content = [...this.content, ...data];
            this.contentTotal = res.data.totalCount || 0;
            resolve();
          }
        }).catch(() => {});
      });
    },
    load() {
      if (this.contentIndex * 10 < this.contentTotal) {
        this.loading = true;
        this.contentIndex++;
        this.getPostData().then(() => {
          this.loading = false;
        });
      }
    },
    scrollTop() {
      document.querySelector(".app").scrollTop = 2900;
    },
    updateMasonry() {
      this.masonry = new Masonry(document.querySelector(".content-body"), {
        itemSelector: "#post-card",
        columnWidth: "#post-card",
        gutter: this.isMobile > 768 ? 16 : 0,
        percentPosition: true
      });
      this.masonry.layout();
    },
    // 添加防抖函数
    debounce(fn, delay) {
      let timer = null;
      return function (...args) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          fn.apply(this, args);
        }, delay);
      };
    },
    // 处理窗口大小变化
    handleResize() {
      this.updateMasonry();
    }
  },
  created() {
    if (this.$route.query.shared === 'true') {
      this.commentObj = {
        id: this.$route.query.id,
        origin: this.$route.query.origin
      };
      this.showComment = true;
    }
    this.getMissionData();
    this.getClubData();
    this.getPostData();
  },
  mounted() {
    // 添加防抖后的窗口大小变化监听
    window.addEventListener("resize", this.debounce(this.handleResize, 1000));
    fetch(leftTip).then(response => response.text()).then(svgContent => {
      this.$refs.left.innerHTML = svgContent;
      this.$refs.left.querySelector("#detail").style.cursor = "pointer";
      this.$refs.left.querySelector("#detail").addEventListener("click", $event => {
        $event.stopPropagation();
        this.showLeftTip = true;
      });
    });
    fetch(rightTip).then(response => response.text()).then(svgContent => {
      this.$refs.right.innerHTML = svgContent;
      this.$refs.right.style.position = "relative";
      this.$refs.right.style.left = "-50px";
      this.$refs.right.querySelector("#scroll").style.cursor = "pointer";
      this.$refs.right.querySelector("#scroll").addEventListener("click", () => {
        // if (this.$refs.editDraftPost?.judgeEditinfo()) {
        //   this.$tipModal({
        //     visible: true,
        //     tipObj: {
        //       title: "Whoa,hold up!",
        //       content:
        //         "Leaving the page will lose the draft, choose to save the draft first",
        //       footer: [
        //         {
        //           name: "Cancel",
        //           type: undefined,
        //           query: "cancel",
        //           class: "default-button",
        //           fn: this.$refs.editDraftPost.receive,
        //         },
        //         {
        //           name: "Save",
        //           type: "primary",
        //           query: "save",
        //           class: "primary-button",
        //           fn: this.$refs.editDraftPost.receive,
        //         },
        //       ],
        //     },
        //   });
        // } else {
        // }
        document.querySelector(".app").scrollTop = 700;
      });
    });
    document.querySelector(".app").addEventListener("scroll", () => {
      if (document.querySelector(".app").scrollTop > 2900) {
        this.showScrollTop = true;
      } else {
        this.showScrollTop = false;
      }
    });
  },
  beforeUnmount() {
    // 移除事件监听
    window.removeEventListener("resize", this.debounce(this.handleResize, 1000));
  },
  updated() {
    this.updateMasonry();
  }
};