import "core-js/modules/es.array.push.js";
import MissionClub from "@/components/MissionClub.vue";
import SelfPerson from "@/components/SelfPerson.vue";
import PostLike from "@/components/PostLike.vue";
export default {
  name: "PersonalManage",
  components: {
    MissionClub,
    SelfPerson,
    PostLike
  },
  data() {
    return {
      index: "",
      isCollapse: false // 默认展开
    };
  },
  created() {
    // 根据屏幕宽度设置初始状态
    this.isCollapse = window.innerWidth <= 768;
    // 监听窗口大小变化
    window.addEventListener('resize', this.handleResize);
    this.index = this.$route.query?.index || "";
  },
  beforeDestroy() {
    // 移除监听器
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isCollapse = window.innerWidth <= 768;
    },
    // 选中左侧导航栏回调
    changeIndex($event) {
      this.index = $event;
      if (this.index === "1") {
        this.$router.push({
          name: "SelfHome"
        }).catch(() => {});
      } else if (this.index === "4-1") {
        this.$router.push({
          name: "CreateMission"
        }).catch(() => {});
      } else if (this.index === "4-2") {
        this.$router.push({
          name: "CreateClub"
        }).catch(() => {});
      } else if (this.index === "4-3") {
        this.$router.push({
          name: "CreatePost"
        }).catch(() => {});
      } else if (this.index === "4-4") {
        this.$router.push({
          name: "CreateAssignment"
        }).catch(() => {});
      } else {
        this.$router.push({
          name: "PersonalManage",
          query: {
            index: this.index
          }
        }).catch(() => {});
      }
    },
    toggleMenu() {
      this.isCollapse = !this.isCollapse;
    }
  },
  created() {
    // 根据屏幕宽度设置初始状态
    this.isCollapse = window.innerWidth <= 768;
    // 监听窗口大小变化
    window.addEventListener('resize', this.handleResize);
    // 接收路由传过来的index展示对应导航栏
    this.index = this.$route.query?.index || "";
  }
};