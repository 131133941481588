import { defineStore } from "pinia";
import country from "@/assets/utils/country";
export const useMyStore = defineStore({
  id: "myStore",
  state: () => ({
    isMobile: 0,
    info: null,
    messageCount:0,
  }),
  actions: {
    judgeIsMobile() {
      this.isMobile = document.body.clientWidth;
      //   this.isMobile =
      //     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      //       navigator.userAgent
      //     );
    },
    async getInfoJudge($http, $particle) {
      if (!$particle.auth.isLogin()) {
        this.info = null;
      } else if ($particle.auth.isLogin()) {
        const info = $particle.auth.getUserInfo();
        if (info.token) {
          var res = await this.checkWeb2Login($http,info.token);
          if(res){
            sessionStorage.setItem("user", JSON.stringify(info));
            await this.getInfo($http);
            this.info = JSON.parse(sessionStorage.getItem("info"));
          }else{
            this.info = null;
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("info");
            $particle.auth.logout();
          }
        }else{
          this.info = null;
          sessionStorage.removeItem("user");
          sessionStorage.removeItem("info");
        }
      }
      if(this.info){
        await this.getMessageCount($http)
      }
      return;
    },


    //判断server端是否登录
    async checkWeb2Login($http,token) {
      return new Promise((resolve) => {
        $http({
          url: "/api/auth/isLogin?token="+token, //请求的后台接口
          method: "get", //get请求方式
        })
         .then(({ data: res }) => {
            if (res.success) {
              resolve(res.data);
            }else{
              resolve(false);
            }
          })
         .catch(() => {
            resolve(false);
         });
      });
    },

    getInfo($http) {
      return new Promise((resolve) => {
        $http({
          url: "/api/auth/selfInfo", //请求的后台接口
          method: "get", //get请求方式
        })
          .then(({ data: res }) => {
            if (res.success) {
              if (res.data.region) {
                res.data.countryImg = require("@/assets/country/" +
                  country.find((e) => e.value === res.data.region).img);
              }
              sessionStorage.setItem("info", JSON.stringify(res.data));
              this.info = res.data;
              resolve(res.data);
            }
          })
          .catch(() => {});
      });
    },
    getMessageCount($http) {
      return new Promise((resolve) => {
        $http({
          url: "/api/notification/listUnread", //请求的后台接口
          method: "get", //get请求方式
        })
          .then(({ data: res }) => {
            if (res.success) {
              this.messageCount=res.data.length;
              resolve(res.data);
            }
          })
          .catch(() => {});
      });
    },
  },
});
