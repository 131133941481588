import "core-js/modules/es.array.push.js";
import CommentAll from "@/components/CommentAll.vue";
import Masonry from "masonry-layout";
export default {
  name: "SelfComment",
  props: ["searchInfo"],
  components: {
    CommentAll
  },
  data() {
    return {
      // 存储mission列表
      mission: [],
      missionTotal: 0,
      // 加载中
      loading: true,
      missionIndex: 1,
      showComment: false,
      commentObj: null,
      delData: null
    };
  },
  computed: {
    // 无法展示更多
    noMore() {
      return this.mission.length === this.missionTotal;
    },
    // 加载中防抖节流
    disabled() {
      return this.loading;
    }
  },
  methods: {
    load() {
      if (this.missionIndex * 10 < this.missionTotal) {
        this.loading = true;
        this.missionIndex++;
        this.getData(false);
      }
    },
    async getData(reset) {
      if (reset) {
        this.mission = [];
        this.missionIndex = 1;
      }
      const data = await this.getAllData();
      this.mission = [...this.mission, ...data];
      this.loading = false;
      return true;
    },
    // filter为all
    getAllData() {
      return new Promise(resolve => {
        const {
          filter
        } = this.searchInfo;
        const params = {
          timeZone: this.$timezone,
          page: this.missionIndex,
          size: 10
        };
        if (filter === "My comment") {
          params.fromUserId = this.info.id;
        } else if (filter === "Reply to me") {
          params.toUserId = this.info.id;
        }
        this.$http({
          url: "/api/comment/list",
          //请求的后台接口
          method: "get",
          //get请求方式
          params
        }).then(({
          data: res
        }) => {
          if (res.success) {
            this.missionTotal = res.data.totalCount;
            const data = res.data.list.map(e => {
              return {
                ...e,
                setTimeout: null,
                likedByMeOrigin: e.likedByMe
              };
            });
            resolve(data);
          }
        }).catch(() => {});
      });
    },
    // 删除
    deleteData(item) {
      this.$http({
        url: "/api/comment/delete?commentId=" + item.id,
        //请求的后台接口
        method: "post" //get请求方式
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.delData = null;
          this.getData(true);
        }
      }).catch(() => {});
    },
    cancelDel() {
      this.delData = null;
    },
    showDel(item) {
      this.delData = JSON.parse(JSON.stringify(item));
      this.$tipModal({
        visible: true,
        tipObj: {
          title: "",
          content: "Are you sure you want to delete this?",
          footer: [{
            name: "Cancel",
            type: undefined,
            query: "cancel",
            class: "default-button",
            fn: this.cancelDel
          }, {
            name: "Confirm",
            type: "primary",
            query: "confirmDelete",
            class: "primary-button",
            fn: () => this.deleteData(this.delData)
          }]
        }
      });
    },
    showCommentModal(item) {
      this.showComment = true;
      this.commentObj = {
        id: item.bisId,
        origin: item.bisName === "ASSIGNMENT" ? "assignment" : "post"
      };
    },
    toOther(item, query) {
      if (item[query] !== this.info.id) {
        this.$router.push({
          name: "FollowOther",
          query: {
            userId: item[query]
          }
        });
      } else {
        this.$router.push({
          name: "PersonalManage",
          query: {
            index: "3"
          }
        });
      }
    }
  },
  created() {
    this.getData(true);
  },
  updated() {
    this.masonry = new Masonry(document.querySelector(".mission-row"), {
      itemSelector: ".mission-col",
      columnWidth: ".mission-col",
      percentPosition: true,
      horizontalOrder: true
    });
    this.masonry.layout();
  }
};