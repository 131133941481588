import "core-js/modules/es.array.push.js";
import CommentAll from "@/components/CommentAll.vue";
import SharedModal from "./SharedModal";
import PostCard from "@/components/PostCard.vue";
import Masonry from "masonry-layout";
export default {
  name: "PostData",
  // 删除重复的 props 定义
  props: {
    searchInfo: {
      type: Object,
      required: true
    },
    canLoad: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CommentAll,
    SharedModal,
    PostCard
  },
  data() {
    return {
      // 存储mission列表
      mission: [],
      missionTotal: 0,
      // 加载中
      loading: true,
      missionIndex: 1,
      otherInfo: null,
      showComment: false,
      commentObj: null,
      reportUrl: null,
      showReport: false,
      delData: null
    };
  },
  computed: {
    // 无法展示更多
    noMore() {
      return this.mission.length === this.missionTotal;
    },
    // 加载中防抖节流
    disabled() {
      return this.loading;
    }
  },
  props: {
    searchInfo: {
      type: Object,
      required: true
    },
    canLoad: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    load() {
      if (this.missionIndex * 10 < this.missionTotal && this.canLoad && !this.loading) {
        this.loading = true;
        this.missionIndex++;
        this.getData(false);
      }
    },
    async getData(reset) {
      if (reset) {
        this.mission = [];
        this.missionIndex = 1;
      }
      const data = await this.getAllData();
      this.mission = [...this.mission, ...data];
      this.loading = false;
      // 添加延时重新布局，并在布局完成后触发滚动检测
      setTimeout(() => {
        this.masonry?.layout();
        window.dispatchEvent(new Event('scroll'));
      }, 1000);
      return true;
    },
    // filter为all
    getAllData() {
      return new Promise(resolve => {
        const {
          filter,
          query,
          origin
        } = this.searchInfo;
        const params = {
          timeZone: this.$timezone,
          page: this.missionIndex,
          size: 10
        };
        if (origin === "post" || origin === "otherPosts") {
          params.timeOrder = "all";
          params.title = query.trim();
          params.creatorId = this.otherInfo.id;
          params.tagDelete = "0";
          if (filter === "Private") {
            params.tagCanPub = "1";
          } else if (filter === "Public") {
            params.tagCanPub = "0";
          } else if (filter === "Selected") {
            // params.filter = "1";
          }
        } else if (origin === "like") {
          params.userId = this.otherInfo.id;
        }
        let url = "";
        if (origin === "post" || origin === "otherPosts") {
          url = filter !== "Selected" ? "api/post/list" : "";
        } else if (origin === "like") {
          url = filter === "Post" ? "api/post/listLiked" : "api/assignment/listLiked";
        }
        this.$http({
          url: "/" + url,
          //请求的后台接口
          method: "get",
          //get请求方式
          params
        }).then(({
          data: res
        }) => {
          if (res.success) {
            this.missionTotal = res.data.totalCount;
            if (origin === "post" || origin === "otherPosts") {
              this.$emit("receiveTotal", {
                name: "post",
                value: res.data.totalCount,
                origin: this.searchInfo.origin
              });
            }
            const data = res.data.list.map(e => {
              return {
                ...e,
                setTimeout: null,
                likedByMeOrigin: e.likedByMe
              };
            });
            resolve(data);
          }
        }).catch(() => {});
      });
    },
    // 是否可以打开阅读权限
    privatePublic(item) {
      this.$http.post("/api/post/togglePubReadAccess?postId=" + item.id).then(({
        data: res
      }) => {
        if (res.success) {
          item.tagCanPub = item.tagCanPub === "0" ? "1" : "0";
        }
      }).catch(() => {});
    },
    cancelDel() {
      this.delData = null;
    },
    componentMenu($event) {
      this.chooseMenu($event.$event, $event.item);
    },
    // 编辑删除分享
    chooseMenu($event, item) {
      if ($event === "delete") {
        // this.deleteData(item);
        this.delData = JSON.parse(JSON.stringify(item));
        this.$tipModal({
          visible: true,
          tipObj: {
            title: "",
            content: "Are you sure you want to delete this?",
            footer: [{
              name: "Cancel",
              type: undefined,
              query: "cancel",
              class: "default-button",
              fn: this.cancelDel
            }, {
              name: "Confirm",
              type: "primary",
              query: "confirmDelete",
              class: "primary-button",
              fn: () => this.deleteData(this.delData)
            }]
          }
        });
      } else if ($event === "edit") {
        this.edit(item);
      } else {
        this.shared(item);
      }
    },
    // 删除
    deleteData(item) {
      this.$http.post("/api/post/delete?postId=" + item.id).then(({
        data: res
      }) => {
        if (res.success) {
          this.getData(true);
          this.delData = null;
        }
      }).catch(() => {});
    },
    // 编辑
    edit(item) {
      this.$router.push({
        name: "CreatePost",
        query: {
          id: item.id,
          origin: "post"
        }
      }).catch(() => {});
    },
    shared(item) {
      this.showReport = true;
      this.reportUrl = {
        url: location.origin + "/#/?share=" + JSON.stringify({
          id: item.id,
          origin: "post"
        }) + "&shared=true",
        data: {
          id: item.id,
          origin: "post"
        }
      };
    },
    report($event) {
      if ($event !== "cancel") {
        this.postShared(this.reportUrl.data, $event);
      }
      this.showReport = false;
    },
    showCommentModal(item) {
      this.showComment = true;
      this.commentObj = {
        id: item.id,
        origin: this.searchInfo.origin === "like" && this.searchInfo.filter === "Submission" ? "assignment" : "post"
      };
    },
    toOther(item) {
      if (item.userId !== this.otherInfo.id && this.searchInfo.origin === "like") {
        this.$router.push({
          name: "FollowOther",
          query: {
            userId: item.userId
          }
        });
      } else {
        this.$router.push({
          name: "PersonalManage",
          query: {
            index: "3"
          }
        });
      }
    },
    dianzan(item, type) {
      if (this.isLogin()) {
        return;
      }
      const {
        filter
      } = this.searchInfo;
      const url = type === "like" ? filter.toLowerCase() : type;
      this.clickLike(item, url);
    }
  },
  created() {
    const userid = sessionStorage.getItem("otherInfo");
    if (userid) {
      this.otherInfo = {
        id: userid
      };
    } else {
      this.otherInfo = JSON.parse(JSON.stringify(this.info));
    }
    this.getData(true);
  },
  updated() {
    this.masonry = new Masonry(document.querySelector(".post-row"), {
      itemSelector: ".post-col",
      columnWidth: ".post-col",
      percentPosition: true,
      horizontalOrder: true
    });
    this.masonry.layout();
  }
};