import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app",
  class: "app"
};
const _hoisted_2 = {
  class: "app-content"
};
import { onMounted } from "vue";
import { RouterView } from "vue-router";
import AppBanner from '@/components/AppBanner.vue';
import { getCurrentInstance } from "vue";
export default {
  __name: 'App',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const resizeFn = () => {
      proxy.judgeIsMobile();
      if (proxy.isMobile <= 768) {
        window.document.documentElement.setAttribute("data-theme", sessionStorage.getItem("theme") || "light");
      } else {
        window.document.documentElement.setAttribute("data-theme", "light");
      }
    };
    onMounted(() => {
      resizeFn();
      window.onresize = () => {
        resizeFn();
      };
    });
    return (_ctx, _cache) => {
      const _component_UserIcon = _resolveComponent("UserIcon");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_UserIcon), _createElementVNode("div", _hoisted_2, [(_openBlock(), _createBlock(_unref(RouterView), {
        key: _ctx.$route.fullPath
      }))])]);
    };
  }
};