import "core-js/modules/es.array.push.js";
import PersonAssign from "@/components/PersonAssign.vue";
import SharedModal from "@/components/SharedModal";
import MissionQuitModal from "@/components/MissionQuitModal";
import { GoogleMap, Marker } from "vue3-google-map";
export default {
  name: "MissionDetail",
  components: {
    PersonAssign,
    SharedModal,
    MissionQuitModal,
    GoogleMap,
    Marker
  },
  data() {
    return {
      // 描述是否过多需要展示viewMore
      showDesMore: false,
      // 描述是否展开
      showDes: false,
      // 数据展示卡片还是列表
      showType: "card",
      searchInfo: {
        filter: "All",
        //区分数据源
        query: "",
        origin: "missionAssign" //区分草稿或作业
      },
      id: "",
      detail: null,
      rank: [],
      // 滚动时顶部出现title
      showScrollTitle: false,
      reportUrl: null,
      showReport: false,
      showMissionQuit: false,
      // 是管理员,joinReward存在，截止日期前三天展示结算按钮
      showComplete: false,
      showFeedback: false
    };
  },
  computed: {},
  methods: {
    openInApp() {
      window.location.href = `funtana://view.mission/${this.id}`;
    },
    // 查看club详情
    clubDetail() {
      this.$router.push({
        name: "ClubDetail",
        query: {
          id: this.detail.officialClubId
        }
      }).catch(() => {});
    },
    indexMethod(index) {
      const key = index + 1;
      return key >= 10 ? key : "0" + key;
    },
    missionRank() {
      this.$router.push({
        name: "MissionRank",
        query: {
          id: this.detail?.id
        }
      }).catch(() => {});
    },
    // 编辑
    edit(type) {
      this.$router.push({
        name: "CreateMission",
        query: {
          id: this.detail?.id,
          editPage: type
        }
      }).catch(() => {});
    },
    // 编辑删除分享
    chooseMenu($event) {
      if ($event === "jvbao") {
        this.jvbao();
      } else if ($event === "quit") {
        this.showMissionQuit = true;
      } else if ($event === "cancel") {
        this.cancelMission();
      } else if ($event === "delete") {
        this.deleteMission();
      } else {
        this.shared();
      }
    },
    // 取消mission
    cancelMission() {
      this.$http.post(`/api/mission/cancel?missionId=${this.id}`).then(({
        data: res
      }) => {
        if (res.success) {
          location.reload();
        }
      }).catch(() => {});
    },
    // 删除mission
    deleteMission() {
      this.$http.post(`/api/mission/delete?missionId=${this.id}`).then(({
        data: res
      }) => {
        if (res.success) {
          this.$router.push({
            name: "SelfHome"
          });
        }
      }).catch(() => {});
    },
    // 退出
    missionQuit($event) {
      if ($event === "Quit") {
        this.join();
      }
      this.showMissionQuit = false;
    },
    join() {
      if (this.isLogin(null)) {
        return;
      }
      if (!this.detail.joinState && (this.detail?.joinReward || this.detail?.joinCharge)) {
        this.$router.push({
          name: "MissionPay",
          query: {
            missionId: this.detail.id
          }
        }).catch(() => {});
        return;
      }
      this.$http.post(`/api/mission/${!this.detail.joinState ? "join" : "leave"}?missionId=` + this.detail.id).then(({
        data: res
      }) => {
        if (res.success) {
          this.getDetail();
        }
      }).catch(() => {});
    },
    shared() {
      this.showReport = true;
      this.reportUrl = {
        url: location.origin + "/#/mission-detail?id=" + this.detail.id + "&shared=true",
        data: {
          id: this.detail.id,
          origin: "mission"
        }
      };
    },
    jvbao() {
      this.$tipModal({
        visible: true,
        tipObj: {
          title: "",
          content: "Report successful",
          footer: [{
            name: "Close",
            type: undefined,
            query: "close",
            class: "default-button"
          }]
        }
      });
    },
    report($event) {
      if ($event !== "cancel") {
        this.postShared(this.reportUrl.data, $event);
      }
      this.showReport = false;
    },
    createAssignment(e) {
      if (this.isLogin(e)) {
        return;
      }
      if (!this.detail.joinState) {
        this.tip("Please join the mission first");
        return;
      }
      this.$router.push({
        name: "CreateAssignment",
        query: {
          missionId: this.detail.id
        }
      }).catch(() => {});
    },
    complete() {
      this.$router.push({
        name: "MissionComplete",
        query: {
          missionId: this.detail.id
        }
      }).catch(() => {});
    },
    back() {
      this.$router.back();
    },
    getDetail() {
      const params = {
        timeZone: this.$timezone,
        missionId: this.id
      };
      this.$http({
        url: "/api/mission/detail",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.detail = res.data;
          if (this.detail?.joinState) {
            const date = this.moment.tz(this.detail.endDate, this.detail.timeZone).clone().tz(this.moment.tz.guess()).clone().add(7, "days");
            const threeDaysAgo = this.moment();
            if (threeDaysAgo.isAfter(date)) {
              if (this.detail.joinReward && this.info?.id === this.detail?.creatorId) {
                this.showComplete = true;
              } else if (this.info?.id !== this.detail?.creatorId) {
                this.showFeedback = true;
              }
            }
          }
          this.$nextTick(function () {
            if (this.$refs.missionDescription.scrollHeight > this.$refs.missionDescription.clientHeight) {
              this.showDesMore = true;
            }
            document.querySelector("#mission-detail").addEventListener("scroll", () => {
              if (this.$refs.missionTitle.getBoundingClientRect().top < 0) {
                this.showScrollTitle = true;
              } else {
                this.showScrollTitle = false;
              }
            });
          });
        }
      }).catch(() => {});
    },
    tip(content) {
      this.$tipModal({
        visible: true,
        tipObj: {
          title: "",
          content,
          footer: [{
            name: "Close",
            type: undefined,
            query: "close",
            class: "default-button"
          }]
        }
      });
    },
    // 获取排行版
    getRank() {
      const params = {
        page: 1,
        size: 10,
        missionId: this.id
      };
      this.$http({
        url: "/api/mission/listGradeRank",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.rank = res.data.list;
        }
      }).catch(() => {});
    },
    toOther(item, query) {
      if (this.isLogin()) {
        return;
      }
      if (item[query] !== this.info.id) {
        this.$router.push({
          name: "FollowOther",
          query: {
            userId: item[query]
          }
        });
      } else {
        this.$router.push({
          name: "PersonalManage",
          query: {
            index: "3"
          }
        });
      }
    },
    rate(item) {
      if (item.rate) {
        this.$http.post(`/api/assignment/putScoreGradeRate?assignmentId=${item.id}&gradeRate=${item.rate}`).then(({
          data: res
        }) => {
          if (res.success) {
            item.rateVisble = false;
            item.rate = item.gradeRate;
            this.getRank();
            this.getDataTable(true);
          }
        }).catch(() => {});
      }
    },
    getData() {
      this.$refs.assignData.getData(true);
    },
    feedback() {
      this.$http({
        url: "/api/mission/reportNotHeld?missionId=" + this.id,
        //请求的后台接口
        method: "post" //get请求方式
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.$tipModal({
            visible: true,
            tipObj: {
              title: "",
              content: "The feedback was successful",
              footer: [{
                name: "Close",
                type: undefined,
                query: "close",
                class: "default-button"
              }]
            }
          });
        }
      }).catch(() => {});
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
    this.getRank();
  }
};