import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-41fad874"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  id: "post-data"
};
const _hoisted_2 = {
  class: "post-row",
  "infinite-scroll-disabled": "disabled",
  "infinite-scroll-distance": "400"
};
const _hoisted_3 = {
  key: 0,
  class: "scroll-text"
};
const _hoisted_4 = {
  key: 1,
  class: "scroll-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PostCard = _resolveComponent("PostCard");
  const _component_CommentAll = _resolveComponent("CommentAll");
  const _component_SharedModal = _resolveComponent("SharedModal");
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.mission, item => {
    return _openBlock(), _createElementBlock("div", {
      class: "post-col",
      key: item.id
    }, [_createVNode(_component_PostCard, {
      item: item,
      searchInfo: $props.searchInfo,
      onComponentMenu: $options.componentMenu
    }, null, 8, ["item", "searchInfo", "onComponentMenu"])]);
  }), 128))])), [[_directive_infinite_scroll, $options.load]]), $data.loading ? (_openBlock(), _createElementBlock("p", _hoisted_3, "loading...")) : _createCommentVNode("", true), $options.noMore ? (_openBlock(), _createElementBlock("p", _hoisted_4, "No more")) : _createCommentVNode("", true), $data.showComment ? (_openBlock(), _createBlock(_component_CommentAll, {
    key: 2,
    obj: $data.commentObj,
    onCommentBack: _cache[0] || (_cache[0] = $event => $data.showComment = false)
  }, null, 8, ["obj"])) : _createCommentVNode("", true), $data.showReport ? (_openBlock(), _createBlock(_component_SharedModal, {
    key: 3,
    url: $data.reportUrl.url,
    onReport: $options.report
  }, null, 8, ["url", "onReport"])) : _createCommentVNode("", true)]);
}